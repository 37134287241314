import React from 'react'
import { Link }  from 'gatsby'
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image'
import {
  contetSlider,
  overlay
} from '../components/styles/Slide.styles'

import Bg from '../images/banner/Brasil_Home.png'
import Bg2 from '../images/banner/TIERIII_Home.png'
import Bg3 from '../images/banner/Dedicado_Home.png'
import Bg4 from '../images/banner/ISO-27701.png'
import Bg5 from '../images/banner/Oferta_Home.png'

const slideImages = [
  Bg,
  Bg2,
  Bg3,
  Bg4,
  Bg5
];

const sliderProperties = {
  indicators: true,
  scale: 1.4,
  duration: 8000,
  pauseOnHover: true
}

const Slideshow = ({location}) => {
    return (
      <div>
        <Slide {...sliderProperties} easing="ease" autoplay="false">
          <div className="each-slide">
            <div css={overlay} style={{ position: 'relative',  'backgroundImage': `url(${slideImages[4]})` }}>
              <div css={contetSlider}>
                <span>Oferta</span>
                <a href='/oferta'>
                  <h3>Servidor dedicado a partir de *497,00/mês e 3 meses de gerenciamento avançado grátis.</h3>
                </a>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div css={overlay} style={{ position: 'relative',  'backgroundImage': `url(${slideImages[3]})` }}>
              <div css={contetSlider}>
                <span>ISO DA LGPD</span>
                <a href='https://hostdime.com.br/blog/certificacao-iso-27701-garante-melhor-controle-de-dados-sensiveis-e-adequacao-a-lgpd'>
                  <h3>Somos a primeira empresa certificada pela ISO 27701 no Brasil</h3>
                </a>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div css={overlay} style={{ position: 'relative',  'backgroundImage': `url(${slideImages[0]})` }}>
              <div css={contetSlider}>
                <span>na mídia</span>
                <Link to='clipagem'>
                  <h3>A HostDime Brasil é destaque na mídia nacional e internacional</h3>
                </Link>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div css={overlay} style={{ position: 'relative', 'backgroundImage': `url(${slideImages[1]})` }}>
              <div css={contetSlider}>
                <span>HostDime é Tier III</span>
                <a href='https://www.hostdime.com.br/blog/classificacao-tier-o-que-e-e-como-isso-diferencia-os-data-centers/'>
                  <h3>O que é a classificação Tier de Data Centers?</h3>
                </a>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div css={overlay} style={{ position: 'relative', 'backgroundImage': `url(${slideImages[2]})` }}>
              <div css={contetSlider}>
                <span>Soluções</span>
                <Link to='servidores-dedicados-nordeste'>
                  <h3>Escolha o Servidor Dedicado ideal para o seu negócio</h3>
                </Link>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    )
};

export default Slideshow;